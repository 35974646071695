<template>
    <div class="furnitureDetail">
        <headComp></headComp>
        <breadComp :breadGoodsInfo="breadGoodsInfo"></breadComp>

        <div class="centerWrapper">
            <div class="detail">
                <div class="pics">
                    <div class="picsShowArea" ref="picsShowArea">
                        <!-- <img src="../assets/imgs/place12.png"/> -->
                        <div class="show" @mouseenter="enterShowImg" @mousemove="moveShowImg">
                            <!-- <img src="../assets/imgs/place12.png"/> -->
                            <img :src="showMainImageList[showMainImageIndex]"/>
                            <div ref="extractor" class="extractor" :style="extractorStyle" v-if="showMainImageList[showMainImageIndex]"></div>
                        </div>
                        <div v-show="showMainImageList.length==0" class="empty">暂无主图</div>
                        <div class="amplifier" v-if="showMainImageList[showMainImageIndex]">
                            <img :src="showMainImageList[showMainImageIndex]" :style="ampliImgStyle"/>
                        </div>
                    </div>
                    <div class="switchArea">
                        <div :class="{'item':1,'active':showMainImageIndex==i}" v-for="(item,i) in showMainImageList" :key="i" @click="showMainImageIndex=i">
                            <!-- <img src="../assets/imgs/place13.png"/> -->
                            <img :src="item"/>
                        </div>
                    </div>
                </div>
                <div class="textDetail">
                    <div class="goodsName">{{goodsDetail.name}}</div>
                    <div class="tagline">【家装狂欢季，颜选品质家】货源火爆预定中！提早下单，优选备货！</div>
                    <div class="priceRela">
                        <div class="onStore">
                            <!-- <span class="lineName" @click="dialogVisible=true">本店价</span> -->
                            <span class="lineName">本店价</span>
                            <wholesalePriceComp :isDetail="true" :goodsId="goodsDetail.goodsId">
                                <div class="val">
                                    <span>￥</span>{{goodsDetail.ourShopPrice}}
                                </div>
                            </wholesalePriceComp>
                        </div>
                        <div class="promotion">
                            <span class="lineName">促销</span>
                            <div class="val">
                                <span class="tag">超值特卖</span>
                                <span>热卖爆款尖货，惊喜超值价，怦然抢购！</span>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="carriage">
                            <span class="lineName">运费</span>
                            <div class="val">
                                <div class="region">
                                    <span>{{goodsDetail.originPlace}}</span>
                                    <span class="to">至</span>
                                    <!-- <span>四川省成都市成华区白莲池街道</span> -->
                                    <el-select v-model="regionIndex" placeholder="请选择">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="deliveryTime">
                                    预计付款后 {{goodsDetail.deliveryCycle}}内发货
                                </div>
                                <div class="deliveryMoney">
                                    {{goodsDetail.shippingInfo}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="salesData">
                        <div class="item">
                            已售<span>{{goodsDetail.goodsId?(goodsDetail.sales+goodsDetail.virtualSales):''}}</span>
                        </div>
                        <div class="item">
                            评价<span>5.0</span>
                        </div>
                    </div>
                    <div class="dash"></div>
                    <div class="specification">
                        <div class="line1" v-show="specList.length>0">
                            <div class="lineName">规格</div>
                            <!-- <div class="val">{{goodsDetail.specification}}</div> -->
                            <div class="spec">
                                <div class="spec_item" :class="chosenSpec==item.spec?'act':''" @click="chosenSpec=item.spec" v-for="(item,i) in specList" :key="i">
                                    {{item.spec}}
                                    <img src="../assets/imgs/effect10.png"/>
                                </div>
                            </div>
                        </div>
                        <div class="line2" v-show="colorList.length>0">
                            <div class="lineName">颜色</div>
                            <!-- <div class="val">{{goodsDetail.specification}}</div> -->
                            <div class="color">
                                <div class="color_item" :class="chosenColor==item.color?'act':''" @click="chosenColor=item.color" v-for="(item,i) in colorList" :key="i">
                                    {{item.color}}
                                    <img src="../assets/imgs/effect10.png"/>
                                </div>
                            </div>
                        </div>
                        <div class="line3" v-show="materialList.length>0">
                            <div class="lineName">材质</div>
                            <!-- <div class="val">{{goodsDetail.specification}}</div> -->
                            <div class="material">
                                <div class="material_item" :class="chosenMaterial==item.material?'act':''" @click="chosenMaterial=item.material" v-for="(item,i) in materialList" :key="i">
                                    {{item.material}}
                                    <img src="../assets/imgs/effect10.png"/>
                                </div>
                            </div>
                        </div>
                        <div class="measure">
                            <div class="lineName">产品尺寸</div>
                            <div class="val">{{chosenSpec?chosenSpec:goodsDetail.productDimension}}</div>
                        </div>
                        <div class="texture">
                            <div class="lineName">产品材质</div>
                            <div class="val">{{chosenMaterial?chosenMaterial:goodsDetail.productMaterial}}</div>
                        </div>
                    </div>
                    <div class="buyNum">
                        <div class="lineName">数量</div>
                        <div class="val">
                            <el-input-number v-model="goodsNum" controls-position="right" @change="handleChange" :min="1" :max="10"></el-input-number>
                        </div>
                    </div>
                    <div class="ope">
                        <div class="lineName"></div>
                        <div class="btns">
                            <el-button class="shopCart btn1" type="danger" @click="addShoppingCart">加入购物车</el-button>
                            <!-- <el-button class="match btn1" type="warning" @click="batchJoinCart">搭配购买</el-button> -->
                            <el-button class="match btn1" type="warning" @click="immediateBuy">直接购买</el-button>
                            <el-button class="collect" :icon="collected?'el-icon-star-on':'el-icon-star-off'" @click="collectAndCancel"></el-button>
                        </div>
                    </div>
                    <div class="commitment">
                        <div class="lineName">服务承诺</div>
                        <div class="val">
                            <div class="item">
                                <img src="../assets/imgs/shield.png"/>
                                售后无忧
                            </div>
                            <div class="item">
                                <img src="../assets/imgs/lighting.png"/>
                                极速发货
                            </div>
                            <div class="item">
                                <img src="../assets/imgs/yuan.png"/>
                                贵就赔
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lookOther">
                    <el-divider>看了又看</el-divider>
                    <div class="list">
                        <div class="item" v-for="(item) in seeAndSeeShow" :key="item.goodsId" @click="clickSeeAndSee(item)">
                            <div class="img">
                                <img :src="item.cover?item.cover:(item.mainImages?item.mainImages.split(',')[0]:require('../assets/imgs/place14.png'))"/>
                            </div>
                            <!-- <img :src="item.cover?item.cover:''"/> -->
                            <div>￥{{item.ourShopPrice}}</div>
                        </div>
                    </div>
                    <div class="btn">
                        <div class="swi">
                            <el-button :style="{'filter':seeAndSeeIndex==1?'opacity(0.5)':'opacity(1)'}" type="text"><i class="el-icon-arrow-left el-icon--right" @click="clickSeeArrow(-1)"></i></el-button>
                            <el-button :style="{'filter':seeAndSeeIndex==Math.ceil(seeAndSeeList.length/3)?'opacity(0.5)':'opacity(1)'}" type="text" @click="clickSeeArrow(1)"><i class="el-icon-arrow-right el-icon--right"></i></el-button>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="combo" v-show="relevantPackageList.length>0">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane :label="item.goodsPackageName" :name="item.goodsPackageId" v-for="(item,i) in relevantPackageList" :key="i"></el-tab-pane>
                    <!-- <el-tab-pane label="厨房套餐" name="2"></el-tab-pane>
                    <el-tab-pane label="客厅套餐" name="3"></el-tab-pane> -->
                </el-tabs>
                <div class="match">
                    <div class="left">
                        <div class="goods">
                            <!-- <img src="../assets/imgs/place15.png"/> -->
                            <img :src="goodsDetail.cover?goodsDetail.cover:(goodsDetail.mainImages?goodsDetail.mainImages.split(',')[0]:'')"/>
                            <div class="name">{{goodsDetail.name}}</div>
                            <div class="price">￥{{goodsDetail.ourShopPrice}}</div>
                        </div>
                        <div class="plus">
                            <img src="../assets/imgs/effect2.png"/>
                        </div>
                        <div class="goods choice" v-for="(item,i) in relevantPackageGoodsList" :key="i">
                            <!-- <img src="../assets/imgs/place16.png"/> -->
                            <img :src="item.cover?item.cover:(item.mainImages?item.mainImages.split(',')[0]:'')"/>
                            <div class="name">
                                <el-checkbox v-model="item.checked" @change="chooseMatchGoods"></el-checkbox>
                                {{item.name}}
                            </div>
                            <div class="price">￥{{item.ourShopPrice}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="line1">
                            <span>{{matchGoodsList.length}}</span>
                            件商品搭配总价
                        </div>
                        <div class="line2">
                            <span>￥</span>
                            {{matchGoodsPrice}}
                        </div>
                        <div class="line3">
                            <el-button class="shopCart btn1" type="danger" @click="batchJoinCart">加入购物车</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="showArea">
                <div class="show">
                    <el-tabs v-model="activeName2" type="card" @tab-click="handleClick2">
                        <el-tab-pane label="商品详情" name="1"></el-tab-pane>
                        <!-- <el-tab-pane label="品牌实力" name="2"></el-tab-pane> -->
                        <!-- <el-tab-pane label="定制流程" name="3"></el-tab-pane> -->
                        <el-tab-pane label="规格参数" name="4"></el-tab-pane>
                        <el-tab-pane label="服务保障" name="5"></el-tab-pane>
                    </el-tabs>
                    <div class="area" v-show="activeName2==1">
                        <!-- 显示部分规格参数 -->
                        <div class="partSpecParam">
                            <div class="inner">
                                <div class="item" v-for="(item,i) in matchFieldList" :key="i" v-if="i<9">
                                    <span class="label">{{item.label}}：</span>
                                    <div class="val">{{item.value}}</div>
                                </div>
                            </div>
                            <div class="moreSpec">
                                <span @click="activeName2='4'">
                                    更多参数
                                    <i class="el-icon-d-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                        <!-- <img v-for="i in 4" :key="i" src="../assets/imgs/place18.png"/> -->
                        <img v-for="(item,i) in detailImageList" :key="i" :src="item?item:require('../assets/imgs/place18.png')"/>
                        <div class="empty" v-show="detailImageList.length==0">
                            <img src="../assets/imgs/place.png" />
                            <div>暂无商品详情</div>
                        </div>
                    </div>
                    <div class="area" v-show="activeName2==2">
                        <!-- <img v-for="i in 4" :key="i" src="../assets/imgs/place18.png"/> -->
                        <!-- <div class="empty" v-show="true">暂无</div> -->
                    </div>
                    <div class="area" v-show="activeName2==3">
                        <!-- <img v-for="i in 4" :key="i" src="../assets/imgs/place18.png"/> -->
                        <!-- <div class="empty" v-show="true">暂无</div> -->
                    </div>
                    <div class="area" v-show="activeName2==4">
                        <div class="specParams">
                            <div class="paramType">
                                <div class="name">商品信息</div>
                                <div class="paramContain">
                                    <div class="cell" v-for="(item,i) in matchFieldList" :key="i">
                                        <span class="cellName">{{item.label}} ：</span>
                                        <div class="cellVal">{{item.value}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="empty" v-show="true">暂无</div> -->
                    </div>
                    <div class="area" v-show="activeName2==5">
                        <img src="https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/fwbz.jpg"/>
                        <!-- <div class="empty" v-show="true">暂无</div> -->
                    </div>
                </div>
                <div class="recommend">
                    <div class="name">相似商品</div>
                    <div class="list">
                        <div class="item" v-for="(item,i) in similarityGoodsList" :key="i" @click="clickSimilarityGoods(item)">
                            <!-- <img src="../assets/imgs/place17.png"/>
                            <div class="text">现代风新潮世代高净套餐</div> -->
                            <div class="cover">
                                <img :src="item.cover?item.cover:(item.mainImages?item.mainImages.split(',')[0]:'')"/>
                            </div>
                            <div class="text">{{item.name}}</div>
                        </div>
                        <div class="empty" v-show="similarityGoodsList.length==0">暂无相似商品</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <el-dialog
            title="预览"
            :visible.sync="dialogVisible"
            width="fit-content"
            :before-close="handleClose"> -->
            <priceTicketComp :goodsObj="goodsDetail" :dialogVisible="dialogVisible" @close="dialogVisible=false"></priceTicketComp>
        <!-- </el-dialog> -->
        
        <loginBoxComp @close="showLoginBox=false" v-show="showLoginBox"></loginBoxComp>
        <shoppingTipComp :num="shoppingNum" v-show="showTip" @continu="showTip=false"></shoppingTipComp>
        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import shoppingTipComp from "@/components/shoppingTipComp.vue"
import wholesalePriceComp from "@/components/wholesalePriceComp"
import loginBoxComp from "@/components/loginBoxComp"
import priceTicketComp from "@/components/priceTicketComp"
import {getGoodsDetail,saveShoppingCart,actionCollect,actionCancelCollect,getSimilarityPackageDetail,
getSeeAndSeeList,getGoodsRelevantPackage,getGoodsRelevantPackageItem,saveShoppingCartBatch,getSimilarityGoodsList,
getGoodsSKUInfo} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp,shoppingTipComp,wholesalePriceComp,loginBoxComp,priceTicketComp
    },
    data(){
        return{
            dialogVisible:false,
            checked:false,
            activeName:'',
            activeName2:"1",
            goodsNum:1,
            regionIndex:1,
            options:[
                {
                    value: 1,
                    label: '四川省成都市成华区白莲池街道'
                },
                // {
                //     value: 2,
                //     label: ''
                // }
            ],
            goodsDetail:{},
            collected:false,
            shoppingNum:0,
            showTip:false,
            detailImageList:[],
            seeAndSeeList:[],//看了又看列表
            seeAndSeeShow:[],//看了又看展示
            seeAndSeeIndex:1,
            showMainImageList:[],
            showMainImageIndex:0,
            relevantPackageList:[],//相关套餐列表
            relevantPackageGoodsList:[],//相关套餐内商品
            matchGoodsList:[],//搭配商品列表
            matchGoodsPrice:"0.00",//搭配商品总价
            similarityGoodsList:[],//相似商品列表
            breadGoodsInfo:{},//面包屑对商品信息
            skuList:[],//sku列表
            specList:[],//规格数组
            colorList:[],//颜色数组
            materialList:[],//材质数组
            chosenSpec:"",
            chosenColor:"",
            chosenMaterial:"",
            chosenSkuGoodsId:"",//选择后确定的goodsId
            fieldList:{
                // "subTitle":"子标题",
                "brandName":"品牌",
                // "brandAddress":"品牌所在地",
                "sn":"型号",                
                "style":"风格",                
                "color":"颜色",
                "productFunction":"产品功能",
                "productMaterial":"产品材质",
                "technology":"工艺",
                "specification":"规格",
                "productDimension":"产品尺寸",
                "sitDepth":"坐深",
                "sitHeight":"坐高",
                "weight":"重量(kg)",
                "packageNumber":"包装件数",
                "packageVolume":"包装体积(m³)",
                "packageDimension":"包装尺寸",
                // "chargeUnit":"计价单位",
                "originPlace":"产地",
                // "canCustom":"是否可定制",
                "isDisassembly":"是否可拆装",
                "canWash":"是否可拆洗",
                "isWoodenFrame":"出厂是否打木架",
                "epStandard":"环保标准",
                "sofaMaterial":"沙发材质",
                "bedMaterial":"床榻材质",
                "mattressMaterial":"床垫材质",
                "tableMaterial":"桌几材质",
                "sofaSize":"沙发规格",
                "bedSize":"床规格",
                "mattressSize":"床垫规格",
                "tableSize":"餐桌规格",
                "sofaType":"沙发类型",
                "bedStruct":"床结构",
                "bedInsideDiameter":"床内径",
                "bedOutsideDiameter":"床外径",
                "fitMattressSpec":"适配床垫规格",
                "mattressThickness":"床垫厚度",
                "suitableCrowd":"适合人群",
                "fabric":"面料材质",
                "springType":"弹簧类型",
                "filler":"填充物",
                "hardness":"软硬度",
                "mesaShape":"台面形状",
                "isHandrail":"是否有扶手",
                "productStruct":"产品结构",
                "innerFrame":"内架",
                "attachment":"配件",
                "mesaMaterial":"台面材质",
                "lampHolderNumber":"灯头个数",
                "isLightSource":"是否带光源",
                "lightSourceType":"光源类型",
                "pitSpace":"坑距",
                "drainageMode":"排水方式",
                "isColdHotWater":"是否冷热水",
                "mesaType":"台面类型",
                "mesaThickness":"台面厚度",
                "isMirrorLamp":"是否含镜灯",
                "floorType":"地板类型",
                "wearproofIndex":"耐磨指标",
                "useUfHeat":"是否可用于地暖",
                "floorWearSpec":"地板损耗说明",
                "doorType":"门类型",
                "lampNumber":"灯头数",
                "ratedVoltage":"额定电压",
                "ratedPower":"额定功率",
                "productModel":"产品型号",
                // "sales":"销量",
                // "stock":"库存",
                // "number":"购物车商品数量",
                // "hotValue":"热度值",
                // "sort":"排序",
                // "goodsNumber":"套餐商品数量",
                // "cover":"商品封面图",
                // "mainImages":"商品主图",
                // "detailImages":"商品详情图",
                // "isShow":"是否显示商品",
                "upkeepInfo":"保养说明",
                "warrantyPeriod":"质保时间",
                "shippingInfo":"运费/送装说明",
                "deliveryCycle":"发货周期",
                "friendlyReminder":"友情提示",
            },//规格参数字段总览
            matchFieldList:[],//匹配后的字段

            extractorStyle:{
                top:0,
                left:0,
            },
            ampliImgStyle:{
                top:0,
                left:0,
            },
            showAmpli:false,
            limitWatch:false,
            showLoginBox:false
        }
    },
    methods: {
        handleClose(done){
            done();
        },
        handleClick(tab,event){
            // console.log(tab)
            // console.log(event)
            this.gainRelevantPackageItem({goodsPackageId:this.activeName})
        },
        handleClick2(tab,event){
            // console.log(tab)
            // console.log(event)
            // console.log(this.activeName2)
        },
        handleChange(){},
        gainGoodsInfo(){
            getGoodsDetail({goodsId:this.$route.query.goodsId}).then(res=>{
                console.log("商品详情",res);
                if(res.code==200){
                    res.data.sales=res.data.sales!=''?Number(res.data.sales):0;
                    res.data.virtualSales=res.data.virtualSales!=''?Number(res.data.virtualSales):0;
                    this.goodsDetail=res.data;
                    if(res.data.detailImages){
                        this.detailImageList=res.data.detailImages.split(",")
                    };
                    if(res.data.mainImages){
                        this.showMainImageList=res.data.mainImages.split(",");
                        this.showMainImageIndex=0;
                    }
                    this.collected=res.data.collected;
                    var {brandId,brandName,goodsTypeId,goodsTypeName,upGoodsTypeId,upGoodsTypeName,sn}=res.data;
                    this.breadGoodsInfo={brandId,brandName,goodsTypeId,goodsTypeName,upGoodsTypeId,upGoodsTypeName,sn}
                    // console.log(this.breadGoodsInfo)
                    
                    var specParamList=[];
                    for(var key in this.fieldList){
                        for(var key2 in res.data){
                            if(key==key2){
                                specParamList.push({
                                    key:key,
                                    label:this.fieldList[key2],
                                    value:res.data[key]
                                })
                                break
                            }
                        }
                    }
                    // console.log(this.fieldList)
                    // console.log(specParamList)
                    this.matchFieldList=specParamList

                    this.gainSimilarityGoods();
                    this.gainGoodsSKU();
                }
            })
        },
        // 加入购物车
        addShoppingCart(){
            if(!localStorage.AOGEYA_loginInfo){
                // this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login');
                this.showLoginBox=true;
                return
            }

            saveShoppingCart({
                goodsId:this.chosenSkuGoodsId!=''?this.chosenSkuGoodsId:this.$route.query.goodsId,
                number:this.goodsNum,
                shoppingCart:{
                    // specification:this.chosenSpec
                }
            }).then(res=>{
                console.log("加入购物车结果",res);
                if(res.code==200){
                    // this.$message.success("操作成功")
                    this.shoppingNum=res.data.number;
                    this.showTip=true;
                }else{
                    this.$message(res.msg||res.message);
                }
            })
        },
        // 直接购买
        immediateBuy(){
            if(!localStorage.AOGEYA_loginInfo){
                // this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login');
                this.showLoginBox=true;
                return
            }

            var obj=JSON.parse(JSON.stringify(this.goodsDetail))
            obj.calcTotalPrice=obj.ourShopPrice;
            obj._goodsNumber=this.goodsNum;
            sessionStorage.immeChosenGoodsList=JSON.stringify([obj]);
            this.$router.push({
                path:"/wishMatch_clear",
                query:{from:2}
            })
        },
        // 收藏与取消
        collectAndCancel(){
            if(!localStorage.AOGEYA_loginInfo){
                // this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login');
                this.showLoginBox=true;
                return
            }

            if(this.collected){
                actionCancelCollect({
                    sourceId:this.$route.query.goodsId,
                    sourceType:1
                }).then(res=>{
                    console.log("取消收藏结果",res);
                    if(res.code==200){
                        this.collected=false;
                        this.$message.success(res.msg||res.message);
                    }else{
                        this.$message(res.msg||res.message);
                    }
                })
            }else{
                actionCollect({
                    sourceId:this.$route.query.goodsId,
                    sourceType:1
                }).then(res=>{
                    console.log("收藏结果",res);
                    if(res.code==200){
                        this.collected=true;
                        this.$message.success(res.msg||res.message);
                    }else{
                        this.$message(res.msg||res.message);
                    }
                })
            }
        },
        // 获取相似套餐
        gainSimilarityCombo(){
            getSimilarityPackageDetail({
                packageId:"4534532234"
            }).then(res=>{
                console.log("相似套餐",res);
                if(res.code==200){
                    
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 获取看了又看列表
        gainSeeAndSeeList(){
            getSeeAndSeeList({goodsId:this.$route.query.goodsId}).then(res=>{
                console.log("看了又看",res);
                if(res.code==200){
                    this.seeAndSeeList=res.data;
                    this.filtSeeShowList(1)
                }
            })
        },
        // 选取看了又看展示商品
        filtSeeShowList(index){
            var arr=[];
            this.seeAndSeeList.forEach((item,i)=>{
                if(i>=3*(index-1)&&i<3*index){
                    arr.push(item)
                }
            })
            this.seeAndSeeShow=arr
        },
        // 点击看了又看左右箭头
        clickSeeArrow(val){
            if(val>0&&this.seeAndSeeIndex<Math.ceil(this.seeAndSeeList.length/3)){
                this.seeAndSeeIndex+=val
                this.filtSeeShowList(this.seeAndSeeIndex)
            }else if(val<0&&this.seeAndSeeIndex>1){
                this.seeAndSeeIndex+=val
                this.filtSeeShowList(this.seeAndSeeIndex)
            }
            console.log(this.seeAndSeeIndex)
        },
        // 获取相关套餐
        gainRelevantPackage(){
            getGoodsRelevantPackage({goodsId:this.$route.query.goodsId}).then(res=>{
                console.log("相关套餐",res);
                if(res.code=200&&res.data[0]){
                    this.relevantPackageList=res.data;

                    this.activeName=res.data[0].goodsPackageId;
                    this.gainRelevantPackageItem(res.data[0]);
                }
            })
        },
        // 根据相关套餐内商品
        gainRelevantPackageItem(item){
            getGoodsRelevantPackageItem({goodsPackageId:item.goodsPackageId}).then(res=>{
                console.log("相关套餐内商品",res);
                if(res.code==200){
                    res.data.forEach(item=>{
                        item.checked=false;
                    })
                    this.relevantPackageGoodsList=res.data;
                }
            })
        },
        // 选择搭配商品时
        chooseMatchGoods(){
            var arr=[];
            var price=0;
            this.relevantPackageGoodsList.forEach(item=>{
                if(item.checked){
                    arr.push(item);
                    price+=parseFloat(item.ourShopPrice)
                }
            })
            this.matchGoodsList=arr;
            price+=parseFloat(this.goodsDetail.ourShopPrice?this.goodsDetail.ourShopPrice:0);
            console.log(price)
            this.matchGoodsPrice=price.toFixed(2);
        },
        // 批量加入购物车
        batchJoinCart(){
            if(!localStorage.AOGEYA_loginInfo){
                // this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login');
                this.showLoginBox=true;
                return
            }
            
            if(!this.goodsDetail.goodsId){
                this.$message("未查询到商品")
                return
            }
            if(this.matchGoodsList.length==0){
                this.$message("尚未选择搭配商品");
                return
            }

            var arr=[{
                goodsId:this.goodsDetail.goodsId,
                // number:this.goodsDetail.number,
                number:this.goodsNum,
            }];
            this.matchGoodsList.forEach(item=>{
                arr.push({
                    goodsId:item.goodsId,
                    number:item.goodsNumber
                })
            })
            saveShoppingCartBatch(arr).then(res=>{
                console.log("批量加入购物车结果",res);
                if(res.code==200){
                    this.shoppingNum=res.data.number;
                    this.showTip=true;
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 获取相似商品
        gainSimilarityGoods(){
            getSimilarityGoodsList({
                pageNum:1,
                pageSize:7,
                goodsTypeIds:this.goodsDetail.goodsTypeId
            }).then(res=>{
                console.log("相似商品",res);
                if(res.code==200){
                    this.similarityGoodsList=res.data.rows;
                }
            })
        },
        // 点击相似商品
        clickSimilarityGoods(item){
            // this.$route.query.goodsId=item.goodsId;
            // location.reload();

            this.$router.push({
                path:"/furnitureDetail",
                query:{goodsId:item.goodsId}
            })
        },
        // 点击看了又看
        clickSeeAndSee(item){
            // this.$route.query.goodsId=item.goodsId;
            // location.reload();

            this.$router.push({
                path:"/furnitureDetail",
                query:{goodsId:item.goodsId}
            })
        },

        //获取商品sku列表
        gainGoodsSKU(){
            getGoodsSKUInfo({goodsId:this.$route.query.goodsId}).then(res=>{
                console.log("商品SKU",res);
                if(res.code==200){
                    this.skuList=res.data;
                    var specArr=[],colorArr=[],materialArr=[];
                    var currentObj={}
                    res.data.forEach(item=>{
                        if(item.isShow=='Y'){
                            if(!specArr.includes(item.spec)&&item.spec!=''){
                                specArr.push(item.spec)
                            }
                            if(!colorArr.includes(item.color)&&item.color!=''){
                                colorArr.push(item.color)
                            }
                            if(!materialArr.includes(item.name)&&item.name!=''){
                                materialArr.push(item.name)
                            }
                        }

                        if(item.goodsId==this.goodsDetail.goodsId){
                            currentObj=item
                        }
                    })
                    this.specList=specArr.map(item=>{
                        return{
                            disabled:false,
                            spec:item
                        }
                    })
                    this.colorList=colorArr.map(item=>{
                        return{
                            disabled:false,
                            color:item
                        }
                    })
                    this.materialList=materialArr.map(item=>{
                        return{
                            disabled:false,
                            material:item
                        }
                    })
                    // if(this.specList.length>0){
                    //     this.chosenSpec=this.specList[0].spec;
                    // }
                    // if(this.colorList.length>0){
                    //     this.chosenColor=this.colorList[0].color;
                    // }
                    // if(this.materialList.length>0){
                    //     this.chosenMaterial=this.materialList[0].material
                    // }
                    console.log(this.specList)
                    console.log(this.colorList)
                    console.log(this.materialList)
                    
                    // console.log(currentObj)
                    if(currentObj.goodsId){
                        this.chosenSpec=currentObj.spec;
                        this.chosenColor=currentObj.color;
                        this.chosenMaterial=currentObj.name;
                    }
                    this.$nextTick(()=>{
                        this.limitWatch=true
                    })
                }
            })
        },
        // 更新其他选项状态
        updateSku(index,n){
            var upSpec=(arr)=>{
                // console.log("spec")
                this.specList.forEach(item=>{
                    var flag=false
                    for(var j=0;j<arr.length;j++){
                        if(arr[j].spec==item.spec){
                            flag=true
                            break
                        }
                    }
                    if(!flag){
                        item.disabled=true;
                        if(this.chosenSpec==item.spec){
                            this.chosenSpec=''
                        }
                    }
                })
            }
            var upColor=(arr)=>{
                // console.log("color")
                this.colorList.forEach(item=>{
                    var flag=false
                    for(var j=0;j<arr.length;j++){
                        if(arr[j].color==item.color){
                            flag=true
                            break
                        }
                    }
                    if(!flag){
                        item.disabled=true;
                        if(this.chosenColor==item.color){
                            this.chosenColor=''
                        }
                    }
                })
            }
            var upMaterial=(arr)=>{
                this.materialList.forEach(item=>{
                    var flag=false
                    for(var j=0;j<arr.length;j++){
                        if(arr[j].name==item.material){
                            flag=true
                            break
                        }
                    }
                    if(!flag){
                        item.disabled=true;
                        if(this.chosenMaterial==item.material){
                            this.chosenMaterial=''
                        }
                    }
                })
            }
            var funs=['upSpec','upColor','upMaterial'];
            if(index==0){
                var arr=this.skuList.filter(item=>item.spec==n)
            }else if(index==1){
                var arr=this.skuList.filter(item=>item.color==n)
            }else if(index==2){
                var arr=this.skuList.filter(item=>item.name==n)
            }

            funs.forEach((item,i)=>{
                if(i!=index){
                    eval(item)(arr)
                }
            })
        },
        // 匹配goodsId
        matchGoods(){
            // if(this.specList.length>0&&this.chosenSpec!=''&&this.chosenColor!=''&&this.chosenMaterial!='')
            // console.log(11)
            for(var i=0;i<this.skuList.length;i++){
                if(this.skuList[i].spec==this.chosenSpec&&this.skuList[i].color==this.chosenColor&&this.skuList[i].name==this.chosenMaterial){
                    this.chosenSkuGoodsId=this.skuList[i].goodsId
                }
            }
            console.log(this.chosenSkuGoodsId)
            if(this.chosenSkuGoodsId!=""){
                // this.$route.query.goodsId=this.chosenSkuGoodsId;
                // location.reload();
                console.log(this.$route.query)
                this.$router.push({
                    path:"/furnitureDetail",
                    query:{goodsId:this.chosenSkuGoodsId}
                })
                // this.$nextTick(()=>{
                //     location.reload()
                // })
            }
        },

        // 移入展示主图时
        enterShowImg(e){
            this.showAmpli=true
            // console.log(e)
            // var div=document.querySelector(".picsShowArea .show");
            // var styles=getComputedStyle(div);
            // var height=parseFloat(styles.height);
            // var width=parseFloat(styles.width);
            // // console.log(getComputedStyle(div))
            // var left=(e.offsetX-110>0?(e.offsetX+110>width?width-220:e.offsetX-110):0);
            // var top=(e.offsetY-110>0?(e.offsetY+110>height?height-220:e.offsetY-110):0);
            // this.extractorStyle.left=left+'px'
            // this.extractorStyle.top=top+'px'
        },
        // 在展示主图中移动时
        moveShowImg(e){
            this.showAmpli=true
            // console.log(e.target)
            // var div=document.querySelector(".picsShowArea .show");
            // var styles=getComputedStyle(div);
            // var height=parseFloat(styles.height);
            // var width=parseFloat(styles.width);
            // console.log(getComputedStyle(this.$refs.picsShowArea).width)
            // console.log(getComputedStyle(this.$refs.extractor).height)
            // var width=580;
            // var height=435;
            var width=parseFloat(getComputedStyle(this.$refs.picsShowArea).width);
            var height=parseFloat(getComputedStyle(this.$refs.picsShowArea).height);
            // console.log(getComputedStyle(div))
            var offsetX=0,offsetY=0;
            if(e.target.className=='extractor'){
                offsetX=e.offsetX+e.target.offsetLeft;
                offsetY=e.offsetY+e.target.offsetTop;
            }else{
                offsetX=e.offsetX;
                offsetY=e.offsetY;
            }
            // var v1=110;
            // var v2=220;
            var v1=parseFloat(getComputedStyle(this.$refs.extractor).width)/2;
            var v2=parseFloat(getComputedStyle(this.$refs.extractor).width);
            var left=(offsetX-v1>0?(offsetX+v1>width?width-v2:offsetX-v1):0);
            var top=(offsetY-v1>0?(offsetY+v1>height?height-v2:offsetY-v1):0);
            // console.log("111",offsetX,offsetY)
            // console.log("222",v1,v2)
            // console.log(left,top)
            
            this.extractorStyle.left=left+'px'
            this.extractorStyle.top=top+'px'
            // console.log(left/580)
            // console.log(top/435)
            this.ampliImgStyle.left=-1160*(Math.round(left/580*100000)/100000)+'px'
            // this.ampliImgStyle.top=-875*(Math.round(top/435*100000)/100000)+'px'
            this.ampliImgStyle.top=-1160*(Math.round(top/580*100000)/100000)+'px'
        },
        pxToRem(v){
            return Math.round(v/16*100000)/100000
        },
    },
    created() {
        this.gainGoodsInfo();
        // this.gainSimilarityCombo();
        this.gainSeeAndSeeList();
        this.gainRelevantPackage();
    },
    watch:{
        'chosenSpec'(n,o){
            if(this.limitWatch){
                this.updateSku(0,n);
                this.matchGoods();
            }
        },
        'chosenColor'(n,o){
            if(this.limitWatch){
                this.updateSku(1,n);
                this.matchGoods();
            }
        },
        'chosenMaterial'(n,o){
            if(this.limitWatch){
                this.updateSku(2,n);
                this.matchGoods();
            }
        },
        '$route'(n,o){
            if(n.query.goodsId){
                // location.reload();
                
                this.gainGoodsInfo();
                // this.gainSimilarityCombo();
                this.gainSeeAndSeeList();
                this.gainRelevantPackage();
            }
        }
    }
}
</script>

<style lang="less" scoped>
.furnitureDetail{
    .detail{
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
        .pics{
            width: 580px;
            .picsShowArea{
                width: 580px;
                // height: 435px;
                height: 580px;
                position: relative;
                &>.show{
                    width: 100%;
                    height: 100%;
                    // background: rgba(0, 0, 0, 0.5);
                    position: relative;
                    border-radius: 6px;
                    background: rgba(245, 245, 245, 1);
                    &>img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        // object-fit: cover;
                        margin: auto;
                        display: block;
                        border-radius: 6px;
                    }
                    .extractor{
                        position: absolute;
                        // top: 0;
                        // left: 0;
                        width: 220px;
                        height: 220px;
                        cursor: move;
                        filter: alpha(opacity=40);
                        -moz-opacity: 0.4;
                        opacity: 0.4;
                        background-color: #ffcc45;
                        display: none;
                    }
                }
                &>img{
                    width: 100%;
                    height: 100%;
                }
                .empty{
                    padding: 50px 0;
                    text-align: center;
                }
                .amplifier{
                    position: absolute;
                    top: 0;
                    left: calc(100% + 20px);
                    z-index: 100;
                    width: 440px;
                    height: 440px;
                    background: #fff;
                    // position: relative;
                    overflow: hidden;
                    display: none;
                    &>img{
                        // width: 200%;
                        // height: 200%;
                        width: 1160px;
                        // height: 870px;
                        height: 1160px;
                        object-fit: contain;
                        position: absolute;
                        // top: 0;
                        // left: 0;
                    }
                }
                &:hover{
                    .show .extractor{
                        display: block;
                    }
                    .amplifier{
                        display: block;
                    }
                }
            }
            .switchArea{
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .item{
                    // width: 96px;
                    // height: 72px;
                    width: 85px;
                    height: 85px;
                    margin: 5px;
                    border: 2px solid transparent;
                    border-radius: 6px;
                    cursor: pointer;
                    &.active{
                        border-color: #B83030;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 6px;
                    }
                }
            }
        }
        .textDetail{
            width: 580px;
            margin: 0 37px;
            .lineName{
                // width: 56px;
                width: 66px;
                margin-right: 17px;
                font-size: 14px;
                font-weight: 700;
                color: rgba(153, 153, 153, 1);
            }
            .goodsName{
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:3;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 29px;
                color: rgba(0, 0, 0, 1);
            }
            .tagline{
                margin-top: 7px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:2;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 29px;
                color: rgba(212, 48, 48, 1);
            }
            .priceRela{
                padding: 10px 0;
                background: rgba(245, 245, 245, 1);
                .onStore{
                    padding: 0 17px;
                    display: flex;
                    align-items: baseline;
                    .val{
                        font-size: 33px;
                        font-weight: 700;
                        color: rgba(200, 64, 57, 1);
                        &>span{
                            font-size: 18px;
                        }
                    }
                }
                .promotion{
                    padding: 0 17px;
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                    .val{
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(102, 102, 102, 1);
                        .tag{
                            padding: 2px 4px;
                            margin-right: 10px;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(212, 48, 48, 1);
                            border: 1px solid rgba(212, 48, 48, 1);
                        }
                    }
                }
                .line{
                    margin: 0 17px;
                    margin-top: 18px;
                    height: 0;
                    border-top: 1.2px dashed rgba(224, 224, 224, 1);
                }
                .carriage{
                    margin: 0 17px;
                    margin-top: 18px;
                    display: flex;
                    align-items: flex-start;
                    .val{
                        .region{
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(102, 102, 102, 1);
                            .to{
                                margin: 0 15px;
                            }
                            .el-select{
                                /deep/.el-input__inner{
                                    width: 230px;;
                                    padding: 0;
                                    border: none;
                                    background: transparent;
                                    font-size: 14px;
                                    // color: rgba(128, 128, 128, 1);
                                    height: 19.2px;
                                    line-height: 19.2px;
                                }
                                /deep/.el-input__icon{
                                    line-height: 19.2px;
                                }
                            }
                        }
                        .deliveryTime{
                            margin-top: 13px;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(102, 102, 102, 1);
                        }
                        .deliveryMoney{
                            margin-top: 13px;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(212, 48, 48, 1);
                        }
                    }
                }
            }
            .salesData{
                padding: 15px;
                display: flex;
                align-items: center;
                .item{
                    padding: 0 60px;
                    border-right: 2px solid rgba(237, 237, 237, 1);
                    font-size: 14px;
                    font-weight: 700;
                    color: rgba(102, 102, 102, 1);
                    &>span{
                        color: rgba(230, 77, 0, 1);
                        margin-left: 5px;
                    }
                    &:last-child{
                        border-right: 0;
                    }
                }
            }
            .dash{
                border-top: 1.2px dashed rgba(224, 224, 224, 1);
            }
            .specification{
                margin-top: 10px;
                .line2,.line3{
                    margin-top: 10px;
                }
                .line1,.line2,.line3{
                    padding: 0 17px;
                    display: flex;
                    align-items: center;
                    .spec,.color,.material{
                        display: flex;
                        flex-wrap: wrap;
                        flex: 1;
                        .spec_item,.color_item,.material_item{
                            border: 1px solid #ddd;
                            padding: 4px 8px;
                            // font-size: 16px;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(102, 102, 102, 1);
                            cursor: pointer;
                            margin-right: 10px;
                            position: relative;
                            margin-top: 10px;
                            &>img{
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 14px;
                                height: 14px;
                                display: none;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            &.act{
                                border-color: #B83030;
                                &>img{
                                    display: inline;
                                }
                            }
                            &:hover{
                                border-color: #B83030;
                            }
                        }
                    }
                }
                .measure{
                    margin-top: 18px;
                    padding: 0 17px;
                    display: flex;
                    align-items: center;
                    .val{
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(102, 102, 102, 1);
                    }
                }
                .texture{
                    margin-top: 18px;
                    padding: 0 17px;
                    display: flex;
                    align-items: center;
                    .val{
                        // width: calc(100% - 71px);
                        flex: 1;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(102, 102, 102, 1);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .buyNum{
                margin-top: 18px;
                padding: 0 17px;
                display: flex;
                align-items: center;
            }
            .ope{
                margin-top: 23px;
                padding: 0 17px;
                display: flex;
                align-items: center;
                .btns{
                    display: flex;
                    align-items: center;
                    .btn1{
                        height: 53px;
                        width: 194px;
                        border-radius: 0;
                        font-size: 21px;
                        font-weight: 700;
                        color: rgba(255, 255, 255, 1);
                    }
                    .shopCart{
                        background: rgba(200, 64, 57, 1);
                    }
                    .match{
                        background: rgba(245, 182, 71, 1);
                    }
                    .collect{
                        height: 53px;
                        /deep/i{
                            font-size: 30px;
                            color: rgba(212, 48, 48, 1);
                        }
                    }
                }
            }
            .commitment{
                padding: 0 17px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                .val{
                    display: flex;
                    align-items: center;
                    .item{
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(56, 56, 56, 1);
                        img{
                            margin-right: 5px;
                        }
                        margin-right: 20px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .lookOther{
            flex: 1;
            .el-divider{
                margin-top: 10px;
                .el-divider__text{
                    font-size: 14px;
                    font-weight: 700;
                    color: rgba(154, 154, 154, 1);
                }
            }
            .list{
                .item{
                    margin-bottom: 15px;
                    cursor: pointer;
                    .img{
                        // width: 100%;
                        width: 236px;
                        height: 147px;
                        // width: 230px;
                        // height: 230px;
                        position: relative;
                        overflow: hidden;
                        &>img{
                            // width: 100%;
                            width: 236px;
                            height: 147px;
                            // width: 230px;
                            // height: 230px;
                            object-fit: cover;
                            transition: all 0.3s;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                    div{
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 700;
                        color: rgba(212, 48, 48, 1);
                        text-align: center;
                    }
                    &:hover{
                        .img>img{
                            width: 246px;
                            height: 157px;
                        }
                    }
                }
            }
            .btn{
                display: flex;
                align-items: center;
                justify-content: center;
                .swi{
                    width: 120px;
                    height: 100%;
                    // border: 1px solid rgba(255, 255, 255, 1);
                    .el-button{
                        width: 50%;
                        height: 100%;
                        margin: 0;
                        font-size: 20px;
                        font-weight: 700;
                        color: rgba(179, 179, 179, 1);
                        border-radius: 0;
                        padding: 0;
                        &.active{
                            background: #B83030;
                            color: #fff;
                        }
                    }
                }
            }
            
        }
    }
    .combo{
        margin-top: 20px;
        .el-tabs{
            /deep/.el-tabs__header{
                border-color: rgba(212, 48, 48, 1);
            }
            /deep/.el-tabs__nav{
                border-color: transparent;
            }
            /deep/.el-tabs__item{
                // height: 47px;
                border-color: transparent;
                font-size: 16px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                &.is-active{
                    background: rgba(212, 48, 48, 1);
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
        .match{
            display: flex;
            justify-content: space-between;
            .left{
                flex: 1;
                margin-right: 20px;
                display: flex;
                align-items: center;
                .goods{
                    width: 183px;
                    img{
                        width: 183px;
                        height: 137px;
                    }
                    .name{
                        margin-top: 4px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 41.6px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp:2;
                        line-clamp:2;
                    }
                    .price{
                        margin-top: 4px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(212, 48, 48, 1);
                    }
                    &.choice{
                        margin-right: 36px;
                        &:last-child{
                            margin-right: 0;
                        }
                        .name{
                            height: 41.6px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp:2;
                            line-clamp:2;
                            /deep/.el-checkbox__inner{
                                // background-color: rgba(212, 48, 48, 1);
                                border-color: rgba(212, 48, 48, 1);
                            }
                            /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                                background-color: rgba(212, 48, 48, 1);
                            }
                        }
                        .price{
                            padding-left: 16px;
                        }
                    }
                }
                .plus{
                    margin: 0 32px;
                    align-self: stretch;
                    padding-top: 55px;
                }
            }
            .right{
                width: 194px;
                .line1{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    color: rgba(128, 128, 128, 1);
                    &>span{
                        color: rgba(212, 48, 48, 1);
                    }
                }
                .line2{
                    text-align: center;
                    margin-top: 8px;
                    font-size: 28px;
                    font-weight: 700;
                    color: rgba(212, 48, 48, 1);
                    &>span{
                        font-size: 19px;
                    }
                }
                .line3{
                    margin-top: 10px;
                    .btn1{
                        height: 53px;
                        width: 194px;
                        border-radius: 0;
                        font-size: 21px;
                        font-weight: 700;
                        color: rgba(255, 255, 255, 1);
                    }
                    .shopCart{
                        background: rgba(200, 64, 57, 1);
                    }
                }
            }
        }
    }
    .showArea{
        margin-top: 30px;
        display: flex;
        align-items: flex-start;
        .recommend{
            width: 300px;
            width: 236px;
            border: 1px solid rgba(212, 212, 212, 1);
            .name{
                padding: 12px 20px;
                font-size: 20px;
                font-weight: 700;
                color: rgba(102, 102, 102, 1);
                border-bottom: 1px solid rgba(212, 212, 212, 1);
            }
            .list{
                display: flex;
                flex-direction: column;
                align-items: center;
                .item{
                    width: 266px;
                    width: 202px;
                    margin-top: 16px;
                    margin-bottom: 22px;
                    cursor: pointer;
                    &:hover{
                        .cover{
                            img{
                                width: 222px;
                                height: 199px;
                            }
                        }
                    }
                    .cover{
                        width: 100%;
                        height: 179px;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 202px;
                            height: 179px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            transition: all 0.3s;
                            object-fit: cover;
                        }
                    }
                    // img{
                    //     // width: 266px;
                    //     height: 179px;
                    //     width: 202px;
                    //     object-fit: cover;
                    // }
                    .text{
                        margin-top: 5px;
                        font-size: 18px;
                        font-weight: 500;
                        color: rgba(56, 56, 56, 1);
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .empty{
                    // width: 100%;
                    padding: 20px 20px;
                }
            }
        }
        .show{
            margin-right: 20px;
            flex: 1;
            .el-tabs{
                /deep/.el-tabs__header{
                    border-color: transparent;
                }
                /deep/.el-tabs__nav{
                    border-color: transparent;
                }
                /deep/.el-tabs__item{
                    // height: 47px;
                    border-color: transparent;
                    font-size: 20px;
                    font-weight: 700;
                    color: rgba(102, 102, 102, 1);
                    &.is-active{
                        // background: rgba(212, 48, 48, 1);
                        color: rgba(212, 48, 48, 1);
                        border-top: 3px solid rgba(212, 48, 48, 1);
                    }
                }
            }
            .area{
                width: 100%;
                &>img{
                    width: 100%;
                    height: auto;
                }
                .empty{
                    padding: 50px 0;
                    text-align: center;
                    &>img{
                        width: 200px;
                        margin-bottom: 10px;
                    }
                }
                .partSpecParam{
                    display: flex;
                    align-items: stretch;
                    margin-bottom: 15px;
                    .inner{
                        width: 75%;
                        display: flex;
                        flex-wrap: wrap;
                        .item{
                            display: flex;
                            width: 33%;
                            padding-right: 10px;
                            font-size: 12px;
                            color: #666;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            .label{
                                width: fit-content;
                            }
                            .val{
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .moreSpec{
                        flex: 1;
                        display: flex;
                        align-items: flex-end;
                        &>span{
                            color: #666;
                            font-size: 12px;
                            cursor: pointer;
                            padding: 5px 0;
                            &:hover{
                                color: #257e5d;
                            }
                        }
                    }
                }
                .specParams{
                    border: 1px solid #e6e6e6;
                    .paramType{
                        display: flex;
                        align-items: stretch;
                        border-bottom: 1px dashed #e6e6e6;
                        &:last-child{
                            border-bottom: none;
                        }
                        &>.name{
                            width: 117px;
                            font-size: 12px;
                            color: #999;
                            background: #f5f5f5;
                            padding: 20px 0;
                            text-align: center;
                        }
                    }
                    .paramContain{
                        flex: 1;
                        padding: 10px 0;
                        .cell{
                            display: flex;
                            align-items: baseline;
                            font-size: 12px;
                            color: #666;
                            .cellName{
                                width: 132px;
                                padding: 10px;
                            }
                            .cellVal{
                                flex: 1;
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>